import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import RefProgImg from "../images/theme-photos-CGpifH-1.png"
import { Link } from "gatsby"

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <Layout>
        <SEO title="Privacy Policy" />
        <div className="about-summary-wrapper">
          <div className="about-summary-content">
            <Container style={{ padding: "100px 0" }}>
              <h2 className="text-center benefits-flair mb-5 text-white">
                Privacy Policy for SMS Communications
              </h2>
              <Row className="justify-content-center">
                <Col lg={9}>
                  {/* <h4 className="mb-5">
                Without our talented candidates like you, there would be no
                PeerSource! When you choose PeerSource to help you find the
                perfect position, we are committed to offer the best recruiting
                experience you have ever received.
              </h4> */}
                  <h3 className="text-white">1. Information We Collect</h3>
                  <p className="mb-5 text-white">
                    We collect your phone number when you verbally opt into
                    receiving SMS messages during an interview with our company.
                    We will not send SMS communications without this verbal
                    consent.
                  </p>

                  <h3 className="text-white">2. How We Use Your Information</h3>
                  <p className="mb-5 text-white">
                    Your phone number is used solely to coordinate interviews,
                    send updates, or share relevant information related to your
                    candidacy for one of our clients’ jobs that you are actively
                    engaged with. We do not sell, share, or use your phone
                    number for other purposes.
                  </p>

                  <h3 className="text-white">3. How to Opt Out</h3>
                  <p className="mb-5 text-white">
                    You may opt out of receiving SMS communications at any time
                    by replying "STOP" to any message we send you or by
                    contacting us directly. Opting out will remove you from our
                    SMS list.
                  </p>

                  <h3 className="text-white">4. Data Security</h3>
                  <p className="mb-5 text-white">
                    We take reasonable measures to protect your phone number and
                    any related information from unauthorized access,
                    disclosure, or misuse.
                  </p>

                  <h3 className="text-white">5. Changes to This Policy</h3>
                  <p className="mb-5 text-white">
                    We may update this SMS Privacy Policy as necessary. If
                    significant changes occur, we’ll notify you by posting the
                    new policy on our website or via SMS.
                  </p>

                  <h3 className="text-white">6. Contact Us</h3>
                  <p className="mb-5 text-white">
                    If you have questions or concerns about our SMS Privacy
                    Policy, please contact us at{" "}
                    <a className="text-white" href="tel:303-867-1111">
                      303-867-1111
                    </a>
                    .
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
